import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

import SimpleTextIcon from './SimpleTextIcon';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import {
  HiddenComponentsSettings,
  HiddenComponentType,
} from '@/models/HiddenSectionSettings';
import MainButton from './buttons/MainButton';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import useHiddenComponents from '@/data/hook/useHiddenComponent';

export type HiddenComponentsProps = {
  sections?: HiddenComponentsSettings;
  className?: ClassNameComponent;
  updateRender: (removals: (keyof HiddenComponentsSettings)[]) => void;
  render?: boolean;
};

export default function HiddenComponents({
  sections,
  className,
  updateRender,
  render,
}: HiddenComponentsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hiddenComponents',
  });

  const hiddenComponents = sections
    ? (Object.keys(sections) as Array<HiddenComponentType>)
    : [];

  const hasHiddenComponents = sections && hiddenComponents.length;

  if (hasHiddenComponents && render)
    return (
      <section
        className={twMerge(
          'flex flex-col py-2.5 px-4 border-neutral-content rounded-xl gap-2.5 w-full md:max-w-96 min-h-fit',
          className?.section,
          'border',
        )}
      >
        <SimpleTextIcon
          title={t('title')}
          icon={<EyeOffIcon className="w-6" />}
          className={{ section: 'text-accent' }}
        />

        <ul
          className={twMerge(
            'flex flex-col gap-3.5 max-h-96',
            className?.ul,
            scrollBarClassName,
          )}
        >
          {hiddenComponents.map(hiddenComponent => (
            <HiddenSection
              key={hiddenComponent}
              section={hiddenComponent}
              updateRender={updateRender}
            />
          ))}
        </ul>
      </section>
    );

  return null;
}

type HiddenSectionProps = Pick<HiddenComponentsProps, 'updateRender'> & {
  section: HiddenComponentType;
};

function HiddenSection({ section, updateRender }: HiddenSectionProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hiddenComponents',
  });

  const { component } = useHiddenComponents(section);

  return (
    <li className="flex justify-between gap-3.5 uppercase p-2.5 border border-secondary-content rounded-xl">
      <SimpleTextIcon
        icon={component?.icon}
        title={component?.title || ''}
        className={{
          tooltip: 'overflow-x-hidden',
          section: 'text-base-content overflow-x-hidden',
          div: 'bg-transparent text-base-content p-0',
          text: 'text-16 truncate',
        }}
        tooltip
      />
      <MainButton
        color="custom"
        text={t('activate')}
        icon={<EyeIcon className="w-4" />}
        className="px-1.5 py-0.5 bg-primary text-base-100"
        onClick={() => updateRender([section])}
      />
    </li>
  );
}
