import {
  ColumnProgress,
  DndOrdenationActivityProgress,
  FlowColumn,
  ItemDND,
  ItemProgress,
} from '@/models/DNDOrdenation';
import {
  ActivityMeta,
  EmptyActivity,
  EmptyActivityWithLesson,
} from '@/models/Activity';
import { BookStatusEnum } from '@/models/Book';
import LessonProgress from '@/models/LessonProgress';
import moment from 'moment';
import Activity, { ActivityTypeEnum } from '@/models/Activity';
import ActivityElement, {
  ChallengeActivity,
  CodeActivityEditElement,
  DNDOrdenationActivity,
  DNDPositionalActivity,
  TextActivityElement,
} from '@/models/ActivityElement';
import ActivityProgress, {
  ActivityProgressAttempt,
  ActivityProgressShortView,
  BestAttempt,
  QuizQuestion,
  TextActivityProgress,
} from '@/models/ActivityProgress';
import Book from '@/models/Book';
import Chapter from '@/models/Chapter';
import { LessonComment } from '@/models/Comment';
import {
  CourseTypeEnum,
  ICoursePath,
  ICourse,
  CourseCategoryEnum,
} from '@/models/Course';
import Klass, {
  DaysOfWeekTypeEnum,
  KlassProgress,
  KlassStatusEnum,
  KlassTypeEnum,
  Teacher,
} from '@/models/Klass';
import { Lesson } from '@/models/Lesson';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import ScheduledLessonReport, {
  GradeEnum,
  PresenceEnum,
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import Unit from '@/models/Unit';
import User, { UserTypeEnum, UserStatusEnum } from '@/models/User';
import UserProfile from '@/models/UserProfile';
import { ChapterStatusEnum } from '@/models/ChapterProgress';
import StudentCourseProgress, {
  StudentAttendanceInfo,
  SimplifiedCourseProgress,
} from '@/models/StudentCourseProgress';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import {
  HistoryEnrollment,
  HistoryLessonReport,
  StudentHistory,
} from '@/models/StudentHistory';
import Rewards, { CategoryEnum, Reward } from '@/models/Rewards';
import ScheduledDay from '@/models/ScheduledDay';
import {
  AssignmentType,
  HomeworkActivity,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import {
  MGActivity,
  MGActivityProgress,
  MGActivityTypeEnum,
} from '@/models/MGActivity';
import { Avatar, Inventory } from '@/models/Avatar';
import { Project } from '@/models/Project';
import { v4 } from 'uuid';
import { ColumnDND } from '@/models/DNDOrdenation';
import { DNDColumn, FlowColumnEnum } from '@/models/DNDColumn';
import { DNDItem } from '@/models/DNDItem';
import DNDPositionalActivityProgress from '@/models/DNDPositionalActivityProgress';
import DNDColumnProgress from '@/models/DNDColumnProgress';
import DNDItemProgress from '@/models/DNDItemProgress';
import {
  ScheduledLessonsStatistics,
  TeacherReportStatistics,
  UnitsStatistics,
} from '@/models/Statistics';
import Tool from '@/models/Tool';
import {
  AverageStudentLessonRating,
  StudentLessonRating,
  TeacherLessonRating,
} from '@/models/LessonRating';
import JsonApiResponse, { Pagination } from '@/models/JsonApiResponse';
import { Notification } from '@/models/Notification';
import { PedagogicalSheet } from '@/models/PedagogicalSheet';
import {
  BehaviorAssessment as StudentBehaviorAssessment,
  BehaviorRatingEnum as StudentBehaviorRatingEnum,
  CommentAssessment as StudentCommentAssessment,
  StudentAssessment,
} from '@/models/StudentAssessment';
import {
  BehaviorAssessment as KlassBehaviorAssessment,
  BehaviorRatingEnum as KlassBehaviorRatingEnum,
  CommentAssessment as KlassCommentAssessment,
  KlassAssessment,
} from '@/models/KlassAssessment';
import { AbsenceNotification } from '@/models/AbsenceNotification';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { createDefaultGame, mapSerializer } from '@ctrlplayteam/astro-code';
import Area from '@/models/Area';
import Tag, { SimpleTag, TagDifficultyEnum } from '@/models/Tag';

type Generator<T> = (item?: Partial<T>) => T;

export function arrayGenerator<T>(
  generator: Generator<T>,
  length: number,
): T[] {
  const array: T[] = [];
  for (let index = 1; index < length + 1; index++) {
    array.push(generator({ id: index } as T));
  }

  return array;
}

export function teacherReportStatisticsGenerator(
  reportStatistics?: Partial<TeacherReportStatistics>,
): TeacherReportStatistics {
  return {
    pendingReportsCount: 1,
    teacher: userGenerator(),
    ...reportStatistics,
  };
}
export function scheduledLessonStatisticsGenerator(
  statistics?: Partial<ScheduledLessonsStatistics>,
): ScheduledLessonsStatistics {
  return {
    lessonsCount: 3,
    hasDoneLessonsCount: 3,
    presencesCount: 2,
    absencesCount: 1,
    ...statistics,
  };
}

export function unitGenerator(unit?: Partial<Unit>): Unit {
  return {
    id: 1,
    address: 'address',
    addressComplement: 'addressComplement',
    addressNumber: '0',
    addressReference: 'addressReference',
    city: 'city',
    email: 'email',
    isActive: true,
    name: 'name',
    neighbourhood: 'neighbourhood',
    recess: [],
    state: 'MG',
    zipcode: '',
    timezone: 'America/Sao_Paulo',
    cnpj: '12345678987',
    corporateName: 'Corporação',
    phone: '12345678',
    ...unit,
  };
}

export function trialDateGenerator(
  trialDate?: Partial<ScheduledDay>,
): ScheduledDay {
  return {
    id: 1,
    unitId: 1,
    dateTime: new Date(),
    teacher: 1,
    seats: 1,
    klass: 0,
    ...trialDate,
  };
}

export function activityElementGenerator(
  activityElement?: Partial<ActivityElement>,
): ActivityElement {
  return {
    identifier: '',
    id: 1,
    order: 1,
    type: ActivityTypeEnum.TEXT,
    ...activityElement,
  };
}

export const quizQuestionGenerator: Generator<QuizQuestion> = quizQuestion => ({
  answer: 'Resposta',
  answerVerifier: '1234567898',
  id: 1,
  ...quizQuestion,
});

export const codeQuestionGenerator: Generator<
  CodeActivityEditElement
> = codeQuestion => {
  return {
    id: 1,
    identifier: 'code',
    order: 1,
    type: ActivityTypeEnum.CODE,
    title: 'Title',
    instructions: 'Instructions',
    tip: 'Tip',
    maxBlocks: 10,
    map: mapSerializer.json.save(createDefaultGame()),
    isValidated: true,
    teacherCode: {},
    goal: {
      coins: 10,
      checkpoints: 1,
    },

    ...codeQuestion,
  };
};

export function activityGenerator(activity?: Partial<Activity>): Activity {
  return {
    id: 1,
    isDone: false,
    name: 'Activity',
    status: VersioningStatusEnum.PUBLISHED,
    questions: [],
    lesson: [],
    tools: [],
    inBank: true,
    isPublicInBank: true,
    author: 1,
    ...activity,
  };
}

export function activityMetaGenerator(
  metaActivity?: Partial<ActivityMeta>,
): ActivityMeta {
  return {
    id: 1,
    originCourse: '',
    lessonsCount: 1,
    ...metaActivity,
  };
}

export function lessonGenerator(lesson?: Partial<Lesson>): Lesson {
  return {
    activities: [],
    activitiesCount: 2,
    book: null,
    courseId: 1,
    description: 'Lesson',
    id: 1,
    instructionsUrl: undefined,
    toolUrl: undefined,
    name: 'Lesson',
    order: 1,
    status: VersioningStatusEnum.PUBLISHED,
    teacherBook: null,
    version: '0',
    readBook: 0,
    tagsIds: [],
    challenge: null,
    inBank: false,
    isPublicInBank: false,
    author: 1,
    tools: [toolGenerator()],
    ...lesson,
  };
}

export function scheduledLessonGenerator(
  scheduledLesson?: Partial<ScheduledLesson>,
): ScheduledLesson {
  return {
    datetime: moment().toISOString(),
    isActive: true,
    hasDone: false,
    id: 1,
    klass: 1,
    notes: 'lorem ipsum',
    order: 1,
    progress: 0.0,
    teacher: 1,
    lesson: lessonGenerator(),
    absences: 2,
    presences: 3,
    url: 'linkAula',
    homeworkActivity: null,
    hasHomeworkActivity: true,
    type: ScheduledLessonTypeEnum.NORMAL,
    ...scheduledLesson,
  };
}

export function scheduledLessonReportGenerator(
  scheduledLessonReport?: Partial<ScheduledLessonReport>,
): ScheduledLessonReport {
  return {
    id: 1,
    scheduledLesson: 1,
    student: 1,
    presence: PresenceEnum.ATTENDED,
    status: ScheduledLessonReportEnum.ACTIVE,
    effort: GradeEnum.ONE,
    challenge: GradeEnum.ONE,
    behavior: GradeEnum.ONE,
    presenceReward: 15,
    ...scheduledLessonReport,
  };
}

export function chapterGenarator(chapter?: Partial<Chapter>): Chapter {
  return {
    id: 1,
    title: 'Título',
    order: 1,
    content: `<p>Parágrafo</p>`,
    book: 1,
    ...chapter,
  };
}

export function lessonCommentGenerator(
  comment?: Partial<LessonComment>,
): LessonComment {
  return {
    id: 1,
    lesson: 1,
    user: userGenerator(),
    comment: '<p>Lorem ipsum dolor sit amet</p>',
    createdAt: new Date(),
    upVotes: [1, 2, 3, 4, 5],
    replies: arrayGenerator(lessonCommentGenerator, 0),
    lastUpdate: new Date(),
    ...comment,
  };
}

export function bookGenarator(book?: Partial<Book>): Book {
  return {
    id: 1,
    filename: 'BOOK',
    uploadedAt: new Date().toISOString(),
    chapters: [chapterGenarator()],
    bookType: 'STUDENT' || 'TEACHER',
    verion: 1,
    status: BookStatusEnum.PUBLISHED,
    tools: [],
    ...book,
  };
}

export function enrollmentGenerator(
  enrollment?: Partial<Enrollment>,
): Enrollment {
  return {
    id: 1,
    klassId: 1,
    student: userGenerator(),
    status: EnrollmentStatusEnum.ACTIVE,
    ...enrollment,
  };
}

export function klassProgressGenerator(
  klassProgress?: Partial<KlassProgress>,
): KlassProgress {
  return {
    progress: 0,
    ...klassProgress,
  };
}

export function userGenerator(user?: Partial<User>): User {
  return {
    id: 1,
    firstName: 'user',
    lastName: 'name',
    email: 'username@user.com',
    username: 'user_name',
    password: 'currentPassword',
    timezone: 'America/Sao_Paulo',
    userType: UserTypeEnum.STUDENT,
    lastLogin: new Date(Date.now()),
    status: UserStatusEnum.REGISTRED,
    unitsIds: [1],
    profile: {} as UserProfile,
    ...user,
  };
}

export function teacherGenerator(teacher?: Partial<Teacher>): Teacher {
  return {
    ...userGenerator(teacher),
    status: UserStatusEnum.REGISTRED,
    studentsChurn: 0.5,
    studentsPresence: 2,
    studentsCount: 1,
    fullName: 'Teacher Name',
  };
}

export function userProfileGenerator(
  userProfile?: Partial<UserProfile>,
): UserProfile {
  return {
    id: 1,
    city: 'Campinas',
    state: 'SP',
    dateOfBirth: '2005-12-03',
    phoneNumber: '',
    bio: 'bio',
    bannerUrl: '',
    socialMedia: [],
    ...userProfile,
  };
}

export function unitStatisticsGenerator(
  unitStatistics?: Partial<UnitsStatistics>,
): UnitsStatistics {
  return {
    totalActiveTeachersCount: 1,
    totalActiveStudentsCount: 2,
    totalActiveClassesCount: 1,
    dailyCancelledEnrollmentsCount: 1,
    dailyLessonReportPresencesCount: 10,
    dailyLessonReportAbsencesCount: 10,
    dailyActiveEnrollmentsCount: 2,
    unitId: 1,
    updatedAt: new Date('2023-10-20T12:24:31.731314-03:00'),
    ...unitStatistics,
  };
}

export default function klassGenerator(klass?: Partial<Klass>): Klass {
  return {
    classRoomUrl: 'google.com',
    coursePathSlug: 'slug',
    dayTimes: [{ day: DaysOfWeekTypeEnum.TUESDAY, time: '10:00:00' }],
    id: 1,
    klassStartDate: new Date('2022-09-23'),
    klassEndDate: new Date(Date.now()),
    status: KlassStatusEnum.IN_PROGRESS,
    enrollsCount: 3,
    teacherId: 1,
    unitId: 1,
    duration: 90,
    name: 'CTO1 / 10:00 / Sab / Ingrid',
    maxCapacity: 10,
    conectaSeats: 0,
    coursePathId: 1,
    klassType: KlassTypeEnum.REGULAR,
    ...klass,
  };
}

export function textActivityProgress(
  textActivityProgress?: Partial<TextActivityProgress>,
): TextActivityProgress {
  return {
    id: 1,
    isDone: false,
    questionId: 1,
    questionOrder: 1,
    questionType: ActivityTypeEnum.TEXT,
    student: 1,
    textActivity: textActivityGenerator(),
    timer: 0,
    ...textActivityProgress,
  };
}

export function textActivityGenerator(
  textActivity?: Partial<TextActivityElement>,
): TextActivityElement {
  return {
    content: 'Content',
    id: 1,
    order: 1,
    identifier: 'identifier',
    title: 'Title',
    subtitle: 'Subtitle',
    type: ActivityTypeEnum.TEXT,
    ...textActivity,
  };
}

export function challengeGenerator(
  challenge?: Partial<ChallengeActivity>,
): ChallengeActivity {
  return {
    id: 1,
    isDone: false,
    name: 'Challenge',
    questions: [],
    status: VersioningStatusEnum.VERSIONING,
    tools: [],
    inBank: false,
    isPublicInBank: false,
    author: 1,
    ...challenge,
  };
}

export const bestAttemptGenerator: Generator<BestAttempt> = bestAttempt => ({
  activityProgress: 1,
  attemptNumber: 2,
  grade: 0.5,
  id: 1,
  ...bestAttempt,
});
export const attemptGenerator: Generator<
  ActivityProgressAttempt
> = attempt => ({
  activityProgress: 1,
  attemptNumber: 2,
  content: [],
  correctQuestions: 2,
  evaluativeQuestions: 2,
  grade: 0.9,
  id: 1,
  ...attempt,
});

export function activityProgressGenerator(
  activityProgress?: Partial<ActivityProgress>,
): ActivityProgress {
  return {
    id: 1,
    activity: activityGenerator(),
    answersRevealed: false,
    grade: 1,
    currentQuestionOrder: 1,
    bestAttempt: undefined,
    questionsProgress: undefined,
    lessonProgress: 1,
    hasDone: false,
    ...activityProgress,
  };
}

export function lessonProgressGenerator(
  lessonProgress?: Partial<LessonProgress>,
): LessonProgress {
  return {
    courseProgressId: 1,
    id: 1,
    studentId: 1,
    lesson: lessonGenerator(),
    progress: 0,
    hasWatchedInstruction: false,
    hasAccessedTool: false,
    activitiesProgress: [activityProgressGenerator()],
    challengeProgress: activityProgressGenerator(),
    scheduledLessonReport: scheduledLessonReportGenerator(),
    homeworkProgress: homeworkProgressGenerator(),
    bookProgress: {
      id: 1,
      student: 1,
      bookId: 1,
      progress: 0,
      chaptersProgress: [
        {
          id: 1,
          chapterId: 1,
          timer: 0,
          chapterOrder: 1,
          status: ChapterStatusEnum.NEVER_OPEN,
          chapterTitle: '',
        },
      ],
    },
    ...lessonProgress,
  };
}

export function coursePathGenerator(
  coursePath?: Partial<ICoursePath>,
): ICoursePath {
  return {
    id: 1,
    status: VersioningStatusEnum.EDITING,
    version: 1,
    course: courseGenerator(),
    slug: 'slug',
    created_at: new Date(),
    last_update: new Date(),
    lessons_count: '1',
    isbnCode: null,
    ...coursePath,
  };
}

export function studentCourseProgressGenerator(
  studentCourseProgress?: Partial<StudentCourseProgress>,
): StudentCourseProgress {
  return {
    id: 1,
    studentId: 1,
    klassId: 1,
    coursePath: coursePathGenerator(),
    progress: 0,
    lessonsProgress: [
      lessonProgressGenerator({
        id: 1,
        lesson: lessonGenerator({ id: 1 }),
        scheduledLessonReport: scheduledLessonReportGenerator(),
      }),
      lessonProgressGenerator({
        id: 2,
        lesson: lessonGenerator({ id: 2 }),
        scheduledLessonReport: scheduledLessonReportGenerator({
          id: 2,
          scheduledLesson: 2,
          student: 1,
          presence: PresenceEnum.MISSED,
        }),
      }),
      lessonProgressGenerator({
        id: 3,
        lesson: lessonGenerator({ id: 3 }),
        scheduledLessonReport: scheduledLessonReportGenerator({
          id: 3,
          scheduledLesson: 3,
          student: 1,
          presence: PresenceEnum.NONE,
        }),
      }),
    ],
    ...studentCourseProgress,
  };
}

export function rewardsGenerator(rewards?: Partial<Rewards>): Rewards {
  return {
    id: 1,
    xp: 100,
    coins: 10,
    gems: 5,
    student: 1,
    ...rewards,
  };
}

export function rewardsValueGenerator(rewardsValue?: Partial<Reward>): Reward {
  return {
    id: 1,
    amount: 10,
    category: CategoryEnum.MAJOR_PROJECT,
    ...rewardsValue,
  };
}

export function avatarGenerator(avatar?: Partial<Avatar>): Avatar {
  return {
    id: 8,
    user: 8,
    hair: {
      color: null,
      hair: {
        id: 14,
        name: 'Hair3',
        rarity: 'COMMON',
        baseColor: '#ffe599',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/e364ab9e-68e9-4c8c-94e5-fbfbf51d723a-urto-3.svg',
        allowUserColor: true,
        default: true,
      },
    },
    face: {
      color: null,
      face: {
        id: 5,
        name: 'Face2',
        rarity: 'COMMON',
        baseColor: '#7f4205',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/67adad9c-c6cc-4b04-bede-ea46473d46c7-face-2.svg',
        allowUserColor: true,
        default: true,
      },
    },
    base: {
      color: '#F0C19B',
      base: {
        id: 2,
        name: 'Corpo2',
        rarity: 'COMMON',
        baseColor: '#d08c48',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/fea2e49e-bb8c-48e6-9a41-a3326b091cc8-corpo2.svg',
        allowUserColor: true,
        default: true,
      },
    },
    hat: {
      color: null,
      hat: null,
    },
    headAcessory: {
      color: null,
      headAcessory: null,
    },
    topFaceAcessory: {
      color: null,
      topFaceAcessory: null,
    },
    bottomFaceAcessory: {
      color: null,
      bottomFaceAcessory: {
        id: 22,
        name: 'Bigode',
        rarity: 'COMMON',
        baseColor: '#fff',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/93a8a939-4976-4a69-96fa-e3dd52b660a8-bigode.svg',
        allowUserColor: true,
        default: true,
      },
    },
    neckAcessory: {
      color: null,
      neckAcessory: null,
    },
    shirt: {
      color: null,
      shirt: {
        id: 18,
        name: 'Shirt2',
        rarity: 'COMMON',
        baseColor: '#3d85c6',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/ca71ef65-98ea-48cf-8788-44025bed3e4e-strada.svg',
        allowUserColor: true,
        default: true,
      },
    },
    coat: {
      color: null,
      coat: null,
    },
    pants: {
      color: null,
      pants: {
        id: 27,
        name: 'Pants',
        rarity: 'COMMON',
        baseColor: '#dfa800',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/f924e2c8-fa8b-412d-b01a-70ef75b1f7b4-ataria.svg',
        allowUserColor: true,
        default: true,
      },
    },
    shoe: {
      color: null,
      shoe: {
        id: 30,
        name: 'Shoe3',
        rarity: 'COMMON',
        baseColor: '#cc0000',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/988aca2a-a5a7-40bd-af82-df62cc20469f-l-star.svg',
        allowUserColor: true,
        default: true,
      },
    },
    leftHandAcessory: {
      color: null,
      handAcessory: null,
    },
    rightHandAcessory: {
      color: null,
      handAcessory: null,
    },
    ...avatar,
  };
}

export function inventoryGenerator(inventory?: Partial<Inventory>): Inventory {
  return {
    avatar: 8,
    hair: [
      {
        id: 12,
        name: 'Hair1',
        rarity: 'COMMON',
        baseColor: '#b45f06',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/df4479a5-b9be-4577-b987-763737b984c0-urto-1.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 13,
        name: 'Hair2',
        rarity: 'COMMON',
        baseColor: '#20124d',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/86da4ac3-b478-49ed-8a32-f4b6472a0415-ongo-1.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 14,
        name: 'Hair3',
        rarity: 'COMMON',
        baseColor: '#ffe599',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/e364ab9e-68e9-4c8c-94e5-fbfbf51d723a-urto-3.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    face: [
      {
        id: 4,
        name: 'Face1',
        rarity: 'COMMON',
        baseColor: '#7f4205',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/84e98fef-3a8e-435e-9f7b-d4c518c1f44e-face-1.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 5,
        name: 'Face2',
        rarity: 'COMMON',
        baseColor: '#7f4205',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/67adad9c-c6cc-4b04-bede-ea46473d46c7-face-2.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 8,
        name: 'Face5',
        rarity: 'COMMON',
        baseColor: '#6fa8dc',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/05ff6219-dbaa-41f5-9ab2-4b8ffd11c127-face-5.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    base: [
      {
        id: 1,
        name: 'Corpo1',
        rarity: 'COMMON',
        baseColor: '#ffcea7',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/a208fca6-dd69-4c75-a522-7e466f852ba3-corpo.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 2,
        name: 'Corpo2',
        rarity: 'COMMON',
        baseColor: '#d08c48',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/fea2e49e-bb8c-48e6-9a41-a3326b091cc8-corpo2.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 3,
        name: 'Corpo3',
        rarity: 'COMMON',
        baseColor: '#7f4205',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/105c60a8-4741-4a48-b6a3-320d4caa3a48-corpo3.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    hat: [],
    headAcessory: [],
    topFaceAcessory: [
      {
        id: 20,
        name: 'Oculos1',
        rarity: 'COMMON',
        baseColor: '#fff',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/b5f0a423-2ba4-4149-aaa7-70f3925f2b47-ulos-1.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 21,
        name: 'Oculos2',
        rarity: 'COMMON',
        baseColor: '#cfe2f3',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/1033edcf-8845-4cfc-a3bd-3038023f81ec-grau-1.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    bottomFaceAcessory: [
      {
        id: 22,
        name: 'Bigode',
        rarity: 'COMMON',
        baseColor: '#fff',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/93a8a939-4976-4a69-96fa-e3dd52b660a8-bigode.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 23,
        name: 'Bubblegum',
        rarity: 'COMMON',
        baseColor: '#ff6baa',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/94130582-c53a-4b91-bf65-daefbaceafbc-iclete.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    neckAcessory: [],
    shirt: [
      {
        id: 17,
        name: 'Shirt1',
        rarity: 'COMMON',
        baseColor: '#f3f6f4',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/41bd25df-50f7-47f2-ba58-a0d93e5660d5-blusa.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 18,
        name: 'Shirt2',
        rarity: 'COMMON',
        baseColor: '#3d85c6',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/ca71ef65-98ea-48cf-8788-44025bed3e4e-strada.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 19,
        name: 'Shirt3',
        rarity: 'COMMON',
        baseColor: '#b45f06',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/91b05f62-02f4-4fc5-bcc3-e0d1eb73b5a4-lanela.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    coat: [],
    pants: [
      {
        id: 24,
        name: 'Shorts',
        rarity: 'COMMON',
        baseColor: '#0b5394',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/0f3de2b1-e7ad-4462-a6c2-9eba0d6f4cfe--jeans.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 26,
        name: 'Skirt2',
        rarity: 'COMMON',
        baseColor: '#0b5394',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/3eedf648-1f0f-4663-bca6-a32fee15a3d8--jeans.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 27,
        name: 'Pants',
        rarity: 'COMMON',
        baseColor: '#dfa800',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/f924e2c8-fa8b-412d-b01a-70ef75b1f7b4-ataria.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    shoe: [
      {
        id: 28,
        name: 'Shoe1',
        rarity: 'COMMON',
        baseColor: '#073763',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/a703ac69-b00c-436f-87d8-a431b3a8428c-sapato.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 29,
        name: 'Shoe2',
        rarity: 'COMMON',
        baseColor: '#61d32d',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/9b29f91f-82d4-40ff-8fdb-4c302e5a8c00-elinho.svg',
        allowUserColor: true,
        default: true,
      },
      {
        id: 30,
        name: 'Shoe3',
        rarity: 'COMMON',
        baseColor: '#cc0000',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/988aca2a-a5a7-40bd-af82-df62cc20469f-l-star.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    handAcessory: [
      {
        id: 10,
        name: 'Livro',
        rarity: 'COMMON',
        baseColor: '#b45f06',
        image:
          'http://localhost:8000/mediafiles/shop/items/images/aa4014dc-4a12-419a-b060-c4ee56e0c63d-livro.svg',
        allowUserColor: true,
        default: true,
      },
    ],
    ...inventory,
  };
}

export function emptyActivityGenerator(
  emptyActivity?: Partial<EmptyActivity>,
): EmptyActivity {
  return {
    id: 1,
    lesson: [],
    name: 'Activity',
    ...emptyActivity,
  };
}

export function emptyActivityWithLessonGenerator(
  emptyActivity?: Partial<EmptyActivityWithLesson>,
): EmptyActivityWithLesson {
  return {
    id: 1,
    lesson: [],
    lessonInfo: lessonGenerator(),
    name: 'Activity',
    ...emptyActivity,
  };
}

export const mgActivityGenerator: Generator<MGActivity> = mgActivity => ({
  activityAuthor: 1,
  activityType: MGActivityTypeEnum.HOMEWORK,
  id: 1,
  isActive: true,
  name: 'Activity',
  status: VersioningStatusEnum.VERSIONING,
  unit: 1,
  ...mgActivity,
});

export const mgActivityProgressGenerator: Generator<
  MGActivityProgress
> = mgActivityProgress => ({
  activity: mgActivityGenerator(),
  grade: null,
  hasDone: false,
  id: 1,
  lastUpdate: moment().toISOString(),
  startDate: moment().toISOString(),
  comment: '',
  ...mgActivityProgress,
});

export const homeworkActivityGenerator: Generator<
  HomeworkActivity
> = homerworkActivity => ({
  activity: activityGenerator(),
  assignmentType: AssignmentType.NOTHING,
  content: 'Content',
  id: 1,
  isActive: true,
  ...homerworkActivity,
});

export const homeworkProgressGenerator: Generator<
  HomeworkActivityProgress
> = homeworkProgress => ({
  activityProgress: mgActivityProgressGenerator(),
  answer: null,
  createdAt: moment().toISOString(),
  homework: homeworkActivityGenerator(),
  id: 1,
  isDone: false,
  status: null,
  studentId: 1,
  ...homeworkProgress,
});

export const toolGenerator: Generator<Tool> = tool => ({
  id: 1,
  image: 'imageUrl',
  name: 'Tool',
  site: 'stie.com',
  isMain: false,
  ...tool,
});

export const projectGenerator: Generator<Project> = project => ({
  createdAt: moment().toString(),
  description: 'Description',
  id: 1,
  image: 'imageUrl',
  lastUpdate: moment().toString(),
  projectUrl: 'projectUrl',
  shared: true,
  title: 'Title',
  tool: toolGenerator(),
  upVoted: false,
  upVotes: 0,
  user: userGenerator(),
  views: 0,
  ...project,
});

export const dndOrdenationActivityGenerator: Generator<
  DNDOrdenationActivity
> = dndOrdenationActivity => ({
  activity: 1,
  columns: arrayGenerator<ColumnDND>(dndOrdenationColumnGenerator, 2),
  id: 1,
  identifier: v4(),
  order: 1,
  subtitle: 'Subtitle',
  title: 'Title',
  type: ActivityTypeEnum.DND_ORDENATION,
  ...dndOrdenationActivity,
});

export const dndOrdenationColumnGenerator: Generator<
  ColumnDND
> = dndOrdenationColumn => ({
  dndOrdenationActivity: 1,
  flow: FlowColumn.VERTICAL,
  id: 1,
  items: arrayGenerator<ItemDND>(dndOrdenationItemGenerator, 3),
  name: 'Column',
  order: 1,
  ...dndOrdenationColumn,
});

export const dndOrdenationItemGenerator: Generator<
  ItemDND
> = dndOrdenationItem => ({
  altText: undefined,
  dndOrdenationColumn: 1,
  id: 1,
  image: undefined,
  order: 1,
  text: 'Item',
  ...dndOrdenationItem,
});

export const dndOrdenationActivityProgressGenerator: Generator<
  DndOrdenationActivityProgress
> = dndOrdenationActivityProgress => ({
  columnsProgress: arrayGenerator<ColumnProgress>(
    dndOrdenationColumnProgressGenerator,
    3,
  ),
  dndOrdenationActivity: dndOrdenationActivityGenerator(),
  id: 1,
  isCurrentQuestion: true,
  isDone: false,
  ...dndOrdenationActivityProgress,
});

export const dndOrdenationColumnProgressGenerator: Generator<
  ColumnProgress
> = dndOrdenationColumnProgress => ({
  id: 1,
  column: dndOrdenationColumnGenerator(),
  itemsProgress: arrayGenerator<ItemProgress>(
    dndOrdenationItemProgressGenerator,
    3,
  ),
  ...dndOrdenationColumnProgress,
});

export const dndOrdenationItemProgressGenerator: Generator<
  ItemProgress
> = dndOrdenationItemProgress => ({
  id: 1,
  item: dndOrdenationItemGenerator(),
  answerOrder: 1,
  ...dndOrdenationItemProgress,
});

export const courseGenerator: Generator<ICourse> = course => ({
  id: 1,
  name: 'Course Generator',
  abbreviation: 'CG',
  courseType: CourseTypeEnum.CTRL_KIDS,
  isActive: true,
  bannerImg: null,
  category: CourseCategoryEnum.ONLINE,
  duration: 90,
  coursePathSlug: 'slug',
  coursePathStatus: VersioningStatusEnum.PUBLISHED,
  lastCoursePathVersion: 1.0,
  knowledgeOrder: 1,
  ...course,
});

export const dndPositionalActivityGenerator: Generator<
  DNDPositionalActivity
> = dndPositionalActivity => ({
  activity: 1,
  questionColumn: dndPositionalColumnGenerator(),
  answerColumn: dndPositionalColumnGenerator(),
  id: 1,
  identifier: v4(),
  order: 1,
  subtitle: 'Subtitle',
  title: 'Title',
  type: ActivityTypeEnum.DND_POSITIONAL,
  ...dndPositionalActivity,
});

export const dndPositionalColumnGenerator: Generator<
  DNDColumn
> = dndPositionalColumn => ({
  id: 1,
  name: 'Column',
  flow: FlowColumnEnum.VERTICAL,
  dragAndDropPositionalActivity: 1,
  items: arrayGenerator<DNDItem>(dndPositionalItemGenerator, 3),
  order: 1,
  editType: 'new',
  errors: [],
  ...dndPositionalColumn,
});

export const dndPositionalItemGenerator: Generator<
  DNDItem
> = dndPositionalItem => ({
  id: 1,
  dndPositionalColumn: 1,
  image: undefined,
  altText: undefined,
  text: 'Item',
  order: 1,
  identifier: v4(),
  editType: 'new',
  errors: [],
  ...dndPositionalItem,
});

export const dndPositionalActivityProgressGenerator: Generator<
  DNDPositionalActivityProgress
> = dndPositionalActivityProgress => ({
  questionColumn: dndPositionalColumnGenerator(),
  columnProgress: dndPositionalColumnProgressGenerator(),
  dndPositionalActivity: dndPositionalActivityGenerator(),
  id: 1,
  isCurrentQuestion: true,
  isDone: false,
  ...dndPositionalActivityProgress,
});

export const dndPositionalColumnProgressGenerator: Generator<
  DNDColumnProgress
> = dndPositionalColumnProgress => ({
  id: 1,
  column: dndPositionalColumnGenerator(),
  itemsProgress: arrayGenerator<DNDItemProgress>(
    dndPositionalItemProgressGenerator,
    3,
  ),
  ...dndPositionalColumnProgress,
});

export const dndPositionalItemProgressGenerator: Generator<
  DNDItemProgress
> = dndPositionalItemProgress => ({
  id: 1,
  item: dndPositionalItemGenerator(),
  answerOrder: 1,
  ...dndPositionalItemProgress,
});

export function teacherLessonRatingGenerator(
  teacherLessonRating?: Partial<TeacherLessonRating>,
): TeacherLessonRating {
  return {
    id: 1,
    userId: 8,
    createdAt: '10/12/2024',
    lessonId: 1,
    rating: 3,
    comment: 'Comentário',
    ...teacherLessonRating,
  };
}

export function studentLessonRatingGenerator(
  studentLessonRating?: Partial<StudentLessonRating>,
): StudentLessonRating {
  return {
    id: 1,
    userId: 8,
    createdAt: new Date('2024-12-10').toISOString(),
    lessonId: 1,
    teacherId: 1,
    teacherRating: 2,
    lessonRating: 3,
    klassName: 'klassName',
    comment: 'Comentário',
    ...studentLessonRating,
  };
}

export const paginationGenerator: Generator<Pagination> = pagination => ({
  count: 1,
  page: 1,
  pages: 1,
  ...pagination,
});

export function simplifiedCourseProgressGenerator(
  simplifiedProgress?: Partial<SimplifiedCourseProgress>,
): SimplifiedCourseProgress {
  return {
    id: 1,
    coursePath: coursePathGenerator(),
    klassId: 1,
    studentId: 1,
    progress: 1,
    enrollmentStatus: EnrollmentStatusEnum.ACTIVE,
    ...simplifiedProgress,
  };
}

export function averageStudentLessonRatingGenerator(
  averageRating?: Partial<AverageStudentLessonRating>,
): AverageStudentLessonRating {
  return {
    lessonsAverage: 2,
    teacherAverage: 3,
    ratingCount: 3,
    ...averageRating,
  };
}

export const jsonApiResponseGenerator = <T>(
  jsonApiResponse?: Partial<JsonApiResponse<T>>,
): JsonApiResponse<T> => ({
  links: {
    first: 'firstLink',
    last: 'lastLink',
    next: 'nextLink',
    prev: null,
  },
  meta: {
    pagination: paginationGenerator(),
  },
  results: [],
  ...jsonApiResponse,
});

export const notificationGenerator: Generator<Notification> = notification => ({
  createdAt: moment().toString(),
  hasRead: false,
  id: 1,
  userId: 5,
  type: null,
  ...notification,
});

export const absenceNotificationGenerator: Generator<
  AbsenceNotification
> = notification => ({
  id: 1,
  notification: notificationGenerator(),
  scheduledLessonReport: scheduledLessonReportGenerator(),
  ...notification,
});

export function pedagogicalSheetGenerator(
  pedagogicalSheet?: Partial<PedagogicalSheet>,
): PedagogicalSheet {
  return {
    id: 1,
    studentId: 1,
    ...pedagogicalSheet,
  };
}

export function studentBehaviorAssessmentGenerator(
  behaviorAssessment?: Partial<StudentBehaviorAssessment>,
): StudentBehaviorAssessment {
  return {
    id: 1,
    attention: StudentBehaviorRatingEnum.ONE,
    organization: StudentBehaviorRatingEnum.ONE,
    initiative: StudentBehaviorRatingEnum.ONE,
    socialization: StudentBehaviorRatingEnum.ONE,
    apprenticeship: StudentBehaviorRatingEnum.ONE,
    createdAt: new Date(),
    updatedAt: new Date(),
    studentAssessmentId: 1,
    ...behaviorAssessment,
  };
}

export function studentCommentAssessmentGenerator(
  commentAssessment?: Partial<StudentCommentAssessment>,
): StudentCommentAssessment {
  return {
    id: 1,
    comment: 'Comment',
    createdAt: new Date(),
    updatedAt: new Date(),
    studentAssessmentId: 1,
    ...commentAssessment,
  };
}

export function studentAssessmentGenerator(
  studentAssessment?: Partial<StudentAssessment>,
): StudentAssessment {
  return {
    id: 1,
    pedagogicalSheetId: 1,
    behaviorAssessment: studentBehaviorAssessmentGenerator(),
    commentAssessment: studentCommentAssessmentGenerator(),
    author: userGenerator({ userType: UserTypeEnum.TEACHER }),
    klassId: 1,
    createdAt: new Date(),
    ...studentAssessment,
  };
}

export function studentAttendanceInfoGenerator(
  studentAttendanceInfo?: Partial<StudentAttendanceInfo>,
): StudentAttendanceInfo {
  return {
    studentMissedCount: 1,
    studentLessonsCount: 1,
    attendancePercentage: 1,
    ...studentAttendanceInfo,
  };
}

export function studentHistoryGenerator(
  studentHistory?: Partial<StudentHistory>,
): StudentHistory {
  return {
    enrollments: [
      historyEnrollmentGenerator({
        enrollmentId: 1,
        status: EnrollmentStatusEnum.AWAITING_PAYMENT,
      }),
      historyEnrollmentGenerator({
        enrollmentId: 1,
        status: EnrollmentStatusEnum.ACTIVE,
      }),
    ],
    lessonsReport: arrayGenerator(historyLessonReportGenerator, 3),
    ...studentHistory,
  };
}

export function historyEnrollmentGenerator(
  enrollment?: Partial<HistoryEnrollment>,
): HistoryEnrollment {
  return {
    enrollmentId: 1,
    status: EnrollmentStatusEnum.ACTIVE,
    klassName: 'klassName',
    createdAt: new Date('2022-09-23'),
    ...enrollment,
  };
}

export function historyLessonReportGenerator(
  lessonReport?: Partial<HistoryLessonReport>,
): HistoryLessonReport {
  return {
    id: 1,
    presence: PresenceEnum.ATTENDED,
    lessonName: 'lessonName',
    lessonOrder: 1,
    scheduledLessonDate: new Date('2022-09-23'),
    scheduledLessonType: ScheduledLessonTypeEnum.NORMAL,
    ...lessonReport,
  };
}

export function klassCommentAssessmentGenerator(
  commentAssessment?: Partial<KlassCommentAssessment>,
): KlassCommentAssessment {
  return {
    id: 1,
    comment: 'Comment',
    createdAt: new Date(),
    updatedAt: new Date(),
    klassAssessmentId: 1,
    ...commentAssessment,
  };
}

export function klassBehaviorAssessmentGenerator(
  behaviorAssessment?: Partial<KlassBehaviorAssessment>,
): KlassBehaviorAssessment {
  return {
    id: 1,
    energy: KlassBehaviorRatingEnum.ONE,
    maturity: KlassBehaviorRatingEnum.ONE,
    speed: KlassBehaviorRatingEnum.ONE,
    createdAt: new Date(),
    updatedAt: new Date(),
    klassAssessmentId: 1,
    ...behaviorAssessment,
  };
}

export function klassAssessmentGenerator(
  klassAssessment?: Partial<KlassAssessment>,
): KlassAssessment {
  return {
    id: 1,
    behaviorAssessment: klassBehaviorAssessmentGenerator(),
    commentAssessment: klassCommentAssessmentGenerator(),
    author: userGenerator({ userType: UserTypeEnum.TEACHER }),
    klassId: 1,
    createdAt: new Date(),
    ...klassAssessment,
  };
}

export function areaGenerator(area?: Partial<Area>): Area {
  return {
    id: 1,
    name: 'Area',
    ...area,
  };
}

export function tagGenerator(tag?: Partial<Tag>): Tag {
  return {
    id: 1,
    description: 'Tag',
    difficulty: TagDifficultyEnum.ADVANCED,
    level: 5,
    areaId: 1,
    ...tag,
  };
}

export function simpleTagGenerator(simpleTag?: Partial<SimpleTag>): SimpleTag {
  return {
    id: 1,
    name: 'Tag',
    ...simpleTag,
  };
}
export function activityProgressShortViewGenerator(
  activityProgressShortView?: Partial<ActivityProgressShortView>,
): ActivityProgressShortView {
  return {
    id: 1,
    activityName: 'Activity',
    activityId: 1,
    lessonId: 1,
    hasDone: false,
    ...activityProgressShortView,
  };
}
