import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import ConditionalRenderer from './ConditionalRenderer';
import Badge from './dataDisplay/Badge';
import Text from './dataDisplay/Text';
import ConditionalWrapper from './ConditionalWrapper';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { Tooltip } from './dataDisplay/Tooltip';

export type SimpleTextIconProps = {
  title: string;
  icon?: ReactNode;
  count?: number;
  className?: ClassNameComponent;
  tooltip?: boolean;
};

export default function SimpleTextIcon({
  title,
  icon,
  count,
  className,
  tooltip,
}: SimpleTextIconProps) {
  return (
    <section
      className={twMerge(
        'flex gap-3.5 items-center select-none',
        className?.section,
      )}
    >
      <ConditionalRenderer condition={icon}>
        <ConditionalWrapper
          condition={!!count}
          wrapper={
            <Badge
              position="-right-1 top-0"
              className="border-none"
              count={count}
            />
          }
        >
          <div
            className={twMerge(
              'p-1 bg-accent text-base-100 rounded-full',
              className?.div,
            )}
          >
            {icon}
          </div>
        </ConditionalWrapper>
      </ConditionalRenderer>
      <ConditionalWrapper
        condition={!!tooltip}
        wrapper={
          <Tooltip
            text={title}
            classNameContainer={className?.tooltip}
            color="accent"
          />
        }
      >
        <Text
          text={title}
          format="rubik-500"
          className={twMerge('text-18 uppercase text-start', className?.text)}
        />
      </ConditionalWrapper>
    </section>
  );
}
