import { useTranslation } from 'react-i18next';

import Text from '../dataDisplay/Text';
import Modal from './Modal';
import RoundedButton from '../buttons/RoundedButton';
import HiddenComponents, { HiddenComponentsProps } from '../HiddenComponents';

export type ModalHiddenComponentsProps = HiddenComponentsProps & {
  visible: boolean;
  onClose(): void;
};

export default function ModalHiddenComponents({
  visible,
  onClose,
  sections,
  updateRender,
}: ModalHiddenComponentsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalHiddenComponents',
  });

  return (
    <Modal
      closeButtonTestId="onCloseButton"
      visible={visible}
      onClose={onClose}
      className="p-9 flex flex-col gap-6 items-center"
    >
      <header className="flex flex-col text-center gap-6">
        <Text
          format="rubik-500"
          className="text-20 text-primary"
          text={t('header.title')}
        />
        <Text
          format="rubik-400"
          className="text-16"
          text={t('header.paragraph')}
        />
      </header>

      <HiddenComponents
        sections={sections}
        updateRender={updateRender}
        render={!!sections}
      />

      <footer className="flex flex-col text-center gap-6 items-center">
        <Text
          format="rubik-400"
          className="text-16"
          text={t('footer.paragraph')}
        />
        <RoundedButton
          color="neutral"
          text={t('footer.button')}
          className="w-full max-w-96"
          onClick={onClose}
        />
      </footer>
    </Modal>
  );
}
