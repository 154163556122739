import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getUserSettings, updateSettings } from '@/functions/userSettings';
import { getTokenResponse, setTokenResponse } from '@/functions/tokenSettings';
import { UserSettings } from '@/models/UserSettings';
import ConsentModal from '@/components/user/settings/ConsentModal';
import { HiddenComponentsSettings } from '@/models/HiddenSectionSettings';

import {
  getHiddenComponents,
  updateHiddenComponents,
} from '@/functions/hiddenComponentsSettings';
import ModalHiddenComponents from '@/components/common/modals/ModalHiddenComponents';

export type Themes = 'light' | 'dark';

export type ConfigContextProps = {
  acknowledged?: boolean;
  userSettings?: UserSettings;
  setUserAcknowledged: (acknowledged: boolean) => void;
  setTheme: (theme: Themes) => void;
  hiddenComponents?: HiddenComponentsSettings;
  hideComponent(updates: HiddenComponentsSettings): void;
  changeUserSettings(updates: UserSettings): void;
  showComponent(removals: (keyof HiddenComponentsSettings)[]): void;
};

const ConfigContext = createContext<ConfigContextProps>(
  {} as ConfigContextProps,
);

export function ConfigProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();

  const [consented, setConsented] = useState(false);

  const tokenResponse = getTokenResponse();

  const [acknowledged, setAcknowledged] = useState<boolean>();

  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    getUserSettings,
  );

  const [hiddenComponents, setHiddenComponents] = useState(
    getHiddenComponents(),
  );

  const setUserAcknowledged = (acknowledged: boolean) => {
    if (tokenResponse)
      setTokenResponse({
        tokenResponse,
        remember: userSettings?.remember === 'true',
      });

    updateSettings({
      updates: { ...userSettings, acknowledged: String(acknowledged) },
    });

    setAcknowledged(acknowledged);
    setConsented(consented);
  };

  const setTheme = useCallback((theme: Themes) => {
    updateSettings({ updates: { theme } });

    document.documentElement.dataset.theme = theme;

    setUserSettings(prev => ({ ...prev, theme }));
  }, []);

  function hideComponent(updates: HiddenComponentsSettings) {
    const updated = { ...hiddenComponents, ...updates };
    setHiddenComponents(updated);

    updateHiddenComponents(updated);

    if (!userSettings?.hasSeenModalHiddenWarning) setModalHidden(true);
  }

  function showComponent(removals: (keyof HiddenComponentsSettings)[]) {
    const settings = { ...hiddenComponents };

    if (settings) {
      for (const removal of removals) {
        delete settings[removal as keyof HiddenComponentsSettings];
      }

      if (Object.keys(settings).length) {
        setHiddenComponents(settings);
        updateHiddenComponents(settings);
      } else {
        setHiddenComponents(undefined);
        updateHiddenComponents(undefined);
      }
    }
  }

  function changeUserSettings(updates: UserSettings) {
    const updated = { ...userSettings, ...updates };
    setUserSettings(updated);
    updateSettings({ updates });
  }

  useEffect(
    () => setAcknowledged(Boolean(userSettings?.acknowledged)),
    [userSettings?.acknowledged],
  );

  useEffect(() => {
    i18n.changeLanguage(userSettings?.language || 'pt_BR');
  }, [i18n, userSettings?.language]);

  useEffect(() => {
    document.documentElement.dataset.theme = userSettings?.theme || 'light';
  }, [setTheme, userSettings?.theme]);

  const [modalHidden, setModalHidden] = useState(false);

  const onCloseModal = () => {
    updateSettings({ updates: { hasSeenModalHiddenWarning: true } });
    setUserSettings(prev => {
      return { ...prev, hasSeenModalHiddenWarning: true };
    });
    setModalHidden(false);
  };

  const values: ConfigContextProps = {
    acknowledged,
    setUserAcknowledged,
    setTheme,
    userSettings,
    hiddenComponents,
    hideComponent,
    changeUserSettings,
    showComponent,
  };

  return (
    <ConfigContext.Provider value={values}>
      {children}
      <ConsentModal
        acknowledged={acknowledged}
        setAcknowledged={setUserAcknowledged}
      />
      <ModalHiddenComponents
        visible={modalHidden}
        onClose={onCloseModal}
        sections={hiddenComponents}
        updateRender={showComponent}
      />
    </ConfigContext.Provider>
  );
}

export default ConfigContext;
