import { UpdateKeywords, Updates, Update } from '@/models/Updates';
import { UPDATES_SETTINGS } from '@/constants';
export type ConditionWhen = (
  keyword: UpdateKeywords,
  value?: Update,
) => boolean;
type DeleteSettings = {
  when: ConditionWhen;
};
export class UpdatesRepository {
  private storage: Storage;
  constructor(storage: Storage) {
    this.storage = storage;
  }

  getAll = () => {
    const readUpdates = this.storage.getItem(UPDATES_SETTINGS);
    if (readUpdates) return JSON.parse(readUpdates) as Updates;
  };

  get = (keyword: UpdateKeywords) => {
    const readUpdates = this.storage.getItem(UPDATES_SETTINGS);
    if (!readUpdates) return null;
    const parsedReadUpdates = JSON.parse(readUpdates) as Updates;
    return parsedReadUpdates[keyword];
  };

  update(keyword: UpdateKeywords, updates: Update) {
    let readUpdates = this.getAll() || {};
    readUpdates = { ...readUpdates, [keyword]: updates };
    this.storage.setItem(UPDATES_SETTINGS, JSON.stringify(readUpdates));
  }

  remove({ when }: DeleteSettings) {
    const readUpdates = this.getAll() || {};
    for (const [key, value] of Object.entries(readUpdates)) {
      if (when(key as UpdateKeywords, value)) {
        delete readUpdates[key as UpdateKeywords];
      }
    }
    this.storage.setItem(UPDATES_SETTINGS, JSON.stringify(readUpdates));
  }

  removeAll = () => this.storage.removeItem(UPDATES_SETTINGS);
}

const updatesRepository = new UpdatesRepository(localStorage);
export default updatesRepository;
