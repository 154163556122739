import { HIDDEN_COMPONENTS_SETTINGS } from '@/constants';
import { HiddenComponentsSettings } from '@/models/HiddenSectionSettings';

export type UpdateDashboardSettings = {
  updates: Partial<HiddenComponentsSettings>;
};

export function updateHiddenComponents(updated?: HiddenComponentsSettings) {
  if (updated)
    localStorage.setItem(HIDDEN_COMPONENTS_SETTINGS, JSON.stringify(updated));
  else localStorage.removeItem(HIDDEN_COMPONENTS_SETTINGS);
}

export const getHiddenComponents = () => {
  const settings = localStorage.getItem(HIDDEN_COMPONENTS_SETTINGS);

  if (settings) return JSON.parse(settings) as HiddenComponentsSettings;
};
